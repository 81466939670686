import {Drawer} from '@material-ui/core';
import clsx from 'clsx';
import {toAbsoluteUrl} from 'core/helpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import React from 'react';
import {Nav, Tab} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {FormattedNumber} from 'react-intl';
import HistoryOrderDetail from '../../HistoryOrderDetail';
import {useOrder} from '../../hooks/useOrder';
import ModalConfirmCustomerOrder from '../../modal/ModalConfirmCustomerOrder';
import ModalCustomerOrder from '../../modal/ModalCustomerOrder';
import './orderPage.scss';
import {listBankCode} from 'app/modules/landing-page-order/constants/ListBankCode';

export default function OrderLandingPageDefault({orderLandingPageDetail}) {
  const {t} = useTranslate();

  const {
    handleLogout,
    handleCopySTK,
    handleCopyAmountOfMoney,
    handleCopyContentOfTrensfer,
    validProductsForOrder,
    showAlert,
    showHistoryOrder,
    setShowHistoryOrder,
    listHistory,
    setHistoryOrder,
    historyOrder,
    orderBatchDetail,
    payCartSuccess,
    payments,
    cartSummary,
    handlePaySuccess,
    showCartOrder,
    setShowCartOrder,
    totalProductCard,
    cartProductsById,
    handelDeleteToCart,
    handelAddToCart,
    totalProductQuantity,
    totalProductAmount,
    setPayments,
    textNote,
    setTextNote,
    setShowModalConfirm,
    userCustomer,
    validateAndEnterCustomer,
    phoneCustomer,
    selectedTab,
    setSelectedTab,
    productByCategory,
    productById,
    price,
    showCustomerOrder,
    setShowCustomerOrder,
    showModalConfirm,
    seller,
    qrOderImage,
    orderCode,
  } = useOrder({orderLandingPageDetail});

  const orderColumns = [
    {
      dataField: 'order_batch.name',
      text: 'Đợt hàng',
      editable: false,
    },
    {
      dataField: 'total_amount',
      text: 'Tổng tiền',
      editable: false,
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={cell} />đ
          </span>
        );
      },
    },
    {
      dataField: 'product',
      text: 'Sản phẩm',
      editable: false,
      formatter: (cell, row) => {
        return row.details?.map((detail) => (
          <div>
            {detail?.product.name} {detail?.package_name} x {detail?.package_quantity}
          </div>
        ));
      },
    },
  ];

  const divStyle = {
    position: 'absolute',
    right: '90px',
  };

  const divStyle1 = {
    position: 'absolute',
    right: '110px',
  };

  if (showAlert) {
    return (
      <div className='px-4 pt-4 bg-white h-100 d-flex justify-content-center'>
        <div style={{maxWidth: '600px'}}>
          <div className='d-flex justify-content-between'>
            <div>
              {phoneCustomer && (
                <div
                  className='text-end cursor-pointer fw-bold text-decoration-underline'
                  onClick={() => setShowHistoryOrder(true)}
                >
                  Lịch sử đặt hàng
                </div>
              )}
            </div>
            <div>
              <i
                onClick={handleLogout}
                className='fs-1 fa-solid fa-right-from-bracket text-dark'
              ></i>
            </div>
          </div>

          <div className='p-3 rounded bg-info text-white my-4'>
            Thời gian cho đợt "{orderBatchDetail.name}" đã hết, chúng tôi đang tiến hành thu hoạch
            và chuẩn bị gửi đến quý khách hàng. Vui lòng quay lại khi có thông báo về đợt hàng sau
          </div>
          <div>
            {
              orderLandingPageDetail?.settings?.banners?.desktop?.length > 0 && (
                <img
                  src={orderLandingPageDetail?.settings?.banners?.desktop[0]}
                  className={`w-100 d-lg-block d-xl-block d-xxl-block d-sm-none d-md-none `}
                  alt=''
                />
              )}
            {
              orderLandingPageDetail?.settings?.banners?.mobile?.length > 0 && (
                <img
                  src={orderLandingPageDetail?.settings?.banners?.mobile[0]}
                  className={`w-100  d-lg-none d-sm-block d-md-block`}
                  alt=''
                />
              )}
          </div>
          <div className=' fw-bold text pt-5 fs-2'>{orderBatchDetail.name}</div>
          <div
            className='fs-5 pt-2'
            style={{textAlign: 'unset'}}
            dangerouslySetInnerHTML={{__html: orderLandingPageDetail.description}}
          ></div>
          <Tab.Container defaultActiveKey={selectedTab}>
            <div className='d-flex align-items-center justify-content-between mb-5 border-bottom'>
              <Nav variant='underline' onSelect={setSelectedTab} as='ul' role='tablist'>
                {Object.entries(productByCategory).map(([key, categories]) => {
                  return (
                    <Nav.Item as='li' key={key}>
                      <Nav.Link
                        eventKey={key}
                        className={`cursor-pointer nav-link fw-bold fs-3 text-warning ${
                          selectedTab === key ? 'border-bottom border-primary border-2' : ''
                        }`}
                      >
                        <span className={clsx({'text-primary': selectedTab === key})}>
                          {categories[0]?.productCategory?.name || 'Khác'}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </div>
            {/*end::Header*/}

            {/*begin::Content*/}
            <div className=''>
              <div className='tab-content'>
                {Object.entries(productByCategory).map(([key, categories]) => {
                  return (
                    <div
                      key={key}
                      id='kt_quick_panel_products'
                      role='tabpanel'
                      className={`tab-pane fade pt-3 pe-5 me-n5 scroll ps ${
                        selectedTab === key ? 'active show' : ''
                      }`}
                    >
                      <div className='mb-15'>
                        <div className='px-2 mt-2 pb-20'>
                          {categories?.map((product) => {
                            return (
                              <div
                                key={product._id}
                                className='row  border-light border-bottom rounded p-1 mb-3'
                              >
                                <div className='col-3 align-items-center '>
                                  <img
                                    src={
                                      product.image_url || toAbsoluteUrl('/media/products/23.png')
                                    }
                                    className={`w-100 rounded ${product.image_url ? '' : ''} `}
                                    alt=''
                                  />
                                </div>
                                <div className='col-9'>
                                  <div className='mb-1 fw-bold fs-4'>{product.name}</div>
                                  {/* <div className='mb-1 d-flex justify-content-between'>
                                    Thời gian cho đợt {orderBatchDetail.name} đã hết, chúng tôi đang tiến hành thu
                                    hoạch và chuẩn bị gửi đến quý khách hàng. Vui lòng quay lại khi
                                    có thông báo về đợt hàng sau
                                  </div> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/*end::Content*/}
          </Tab.Container>
        </div>
      </div>
    );
  }

  if (showHistoryOrder) {
    return (
      <div>
        <div className='card card-custom gutter-b'>
          <div className='card-body'>
            <div onClick={() => setShowHistoryOrder(null)} className='text-end'>
              <i className='fs-1 fa-solid fa-xmark text-dark'></i>
            </div>
            {Array.isArray(listHistory) && listHistory.length ? (
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                bootstrap4
                hover
                keyField='_id'
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    setHistoryOrder(row);
                  },
                }}
                data={listHistory === null ? [] : listHistory}
                columns={orderColumns}
              ></BootstrapTable>
            ) : (
              <div className='text-center'>
                <div onClick={() => setShowHistoryOrder(null)} className='text-end'>
                  <i className='fs-1 fa-solid fa-xmark text-dark'></i>
                </div>
                Không có có lịch sử đặt hàng
              </div>
            )}
          </div>
        </div>
        {historyOrder && (
          <Drawer
            BackdropProps={{invisible: true}}
            anchor='right'
            open={historyOrder ? true : false}
            onClose={() => setHistoryOrder(null)}
          >
            <HistoryOrderDetail
              setShowDetail={setHistoryOrder}
              orderBatch={orderBatchDetail}
              orderId={historyOrder._id}
            />
          </Drawer>
        )}
      </div>
    );
  }

  const bank = listBankCode.find((bank) => bank?.value === seller?.settings?.bank);

  if (payCartSuccess && payments === 'transfer') {
    return (
      <div className='d-flex px-4 bg-white flex-column align-items-center pt-10 h-100 page-qr'>
        <div className='mt-3 fs-2 text-info fw-bold'>Chúc mừng bạn đã đặt hàng thành công!</div>
        <div className='mt-3 fs-5 text-center'>
          Vui lòng chuyển khoản theo một trong hai cách dưới đây:
        </div>
        <div className='mt-3 fs-5 text-center'>
        Cách 1: Chụp ảnh mã QR bên dưới và thanh toán bằng cách quét QR
        </div>
        <div className='mt-3 fs-5 text-center'>
            <img
              src={qrOderImage || toAbsoluteUrl('/media/qr/1039901029.jpg')}
              className='w-250px'
              alt=''
            />
        </div>
        <div className='mt-3 fs-5 text-center'>
          Cách 2: Chuyển khoản tới ngân hàng:
        </div>
        <div className='mt-3 fs-5 d-flex align-items-center'>
          <span>STK: </span>
          <span className='fw-bolder'>{seller?.settings?.bankAccount || 1039901029}</span>{' '}
          <span
            onClick={handleCopySTK}
            className='border fw-normal text-gray-600 fs-7 ms-2 rounded-1'
          >
            Copy <i className='text-primary lar la-copy ms-1'></i>
          </span>
        </div>
        {!seller?.settings?.bankAccount ? (
          <>
           <div className='mt-3 fs-5 '>PHAM THI NGOC ANH</div>
            <div className='mt-3 fs-5 '>Vietcombank (VCB)</div>
          </>
        ) : (
          <>
          <div className='mt-3 fs-5 text-uppercase'>{seller.settings.name}</div>
            <div className='mt-3 fs-5 '>{bank?.label}</div>
          </>
        )}
        <div className='mt-3 fs-5  text-danger fw-bold'>
          Số tiền: <FormattedNumber value={cartSummary.totalAmount} />
          <span
            onClick={handleCopyAmountOfMoney}
            className='border fw-normal text-gray-600 fs-7 ms-2 rounded-1'
          >
            Copy <i className='text-primary lar la-copy ms-1'></i>
          </span>
        </div>
        <div className='mt-3 fs-5 text-center text-warning fw-bolder'>
          Nội dung chuyển khoản: {`${orderCode} ${userCustomer?.phone}` || 'Tên - STĐ'}
          <span
            onClick={handleCopyContentOfTrensfer}
            className='border fw-normal text-gray-600 fs-7 ms-2 rounded-1'
          >
            Copy <i className='text-primary lar la-copy ms-1'></i>
          </span>
        </div>
        <div className='mt-3 fs-5 text-center'>
        Cảm ơn quý khách đã đồng hành cùng trang trại Darwin!
        </div>
        <div
          className='w-50px h-50px shadow rounded-circle bg-primary d-flex align-items-center justify-content-center mt-auto mb-20 cursor-pointer'
          onClick={() => handlePaySuccess()}
        >
          <i className='las la-times text-dark fs-1 text-white'></i>
        </div>
        <div className='h-40px'></div>
      </div>
    );
  }

  if (payCartSuccess) {
    return (
      <div className='d-flex px-4 bg-white flex-column align-items-center pt-10 h-100 page-qr'>
        <div className='mt-3 fs-2 text-info fw-bold'>Chúc mừng bạn đã đặt hàng thành công!</div>
        <div className='mt-3 fs-5 text-center'>
          Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất.
        </div>
        <div
          className='w-50px h-50px shadow rounded-circle bg-primary d-flex align-items-center justify-content-center mt-auto mb-20 cursor-pointer'
          onClick={() => handlePaySuccess()}
        >
          <i className='las la-times text-dark fs-1 text-white'></i>
        </div>
        <div className='h-40px'></div>
      </div>
    );
  }

  if (showCartOrder) {
    return (
      <>
        <div className='px-4 pt-4 bg-white d-flex justify-content-center h-100'>
          <div style={{maxWidth: '600px'}}>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='mt-4 fs-2 fw-bold position-relative'>
                {t('order_store')}
                <span className='fw-normal position-absolute left-100 ms-2'><p className='fs-7'> {`(${cartSummary.totalProduct})`}</p></span>
              </div>
              <i
                className='las la-times text-dark fs-1'
                onClick={() => setShowCartOrder(false)}
              ></i>
            </div>
            <div className='px-2 mt-2 pb-15'>
              {totalProductCard ? (
                validProductsForOrder.map((product) => {
                  const cartProduct = cartProductsById?.[product?._id];
                  if (cartProduct) {
                    return (
                      <div
                        key={product._id}
                        className='row shadow border-light bg-white border-bottom rounded p-1 mb-3'
                      >
                        <div className='col-2 align-items-center'>
                          <img
                            src={product.image_url || toAbsoluteUrl('/media/products/23.png')}
                            className={`w-100 rounded ${product.image_url ? '' : 'opacity-25'} `}
                            alt=''
                          />
                        </div>
                        <div className='col-10'>
                          <div className='mb-1 fw-bold'>{product.name}</div>
                          <div>
                            <span className=''>
                              Có sẵn: {product.availableQuantity} {product.unit_name || 'kg'}
                            </span>
                          </div>
                          <div className='product-package mt-3'>
                            {product?.packages?.map((packageItem) => {
                              const cartPackage = cartProduct?.[packageItem?._id];
                              if (!cartPackage) {
                                return <React.Fragment key={packageItem._id}></React.Fragment>;
                              }
                              return (
                                <div className='d-flex mb-2' key={packageItem._id}>
                                  <div className='d-flex align-items-center mt-auto mb-2'>
                                    <span>{packageItem.name} </span>
                                    <span className=' mx-2'>x {cartPackage}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className='product-package w-100 mt-3'>
                            {product?.packages?.map((packageItem) => {
                              const cartPackage = cartProduct?.packageItems?.[packageItem?._id];
                              return (
                                <div
                                  className='d-flex w-100 justify-content-end align-items-center mt-auto mb-2 position-relative'
                                  key={packageItem._id}
                                >
                                  <span className='w-100px'>{packageItem.name}</span>
                                  <div className='d-flex'>
                                    {cartPackage?.packageQuantity ? (
                                      <div className='minus-sign-order'>
                                        <i
                                          className={`las la-minus border border-dark text-dark rounded p-1 h-25px w-25px d-flex align-items-center justify-content-center ${clsx(
                                            {'opacity-0': !cartPackage}
                                          )}`}
                                          onClick={() => {
                                            if (cartPackage) {
                                              handelDeleteToCart(packageItem, product);
                                            }
                                          }}
                                        ></i>
                                      </div>
                                    ) : null}
                                    <span
                                      className={`fw-bold  mx-2 h-25px w-25px d-flex align-items-center justify-content-center`}
                                    >
                                      {cartPackage?.packageQuantity || ''}
                                    </span>
                                    <i
                                      className='las la-plus bg-primary text-white rounded  p-1 h-25px w-25px d-flex align-items-center justify-content-center'
                                      onClick={() => handelAddToCart(packageItem, product)}
                                    ></i>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className='d-flex justify-content-between'>
                            {totalProductQuantity(product) > 0 && (
                              <div className='me-2 '>
                                <span>
                                  Tổng đặt: {totalProductQuantity(product)}{' '}
                                  {product.unit_name || 'kg'}
                                </span>
                              </div>
                            )}
                            {totalProductAmount(product) > 0 && (
                              <div className='me-2 text-danger'>
                                <span>
                                  <FormattedNumber value={totalProductAmount(product)} /> đ
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return <React.Fragment key={product._id}></React.Fragment>;
                })
              ) : (
                <div>Không có sản phẩm này trong giỏ hàng</div>
              )}
            </div>
            <div className='mt-4'>
              <div className='fw-bold'>Hình thức thanh toán</div>
              <div className='mt-4'>
                <label className='radio radio-lg radio-single d-flex align-items-center'>
                  <input
                    className='w-15px h-15px'
                    name='payments'
                    type='radio'
                    onChange={(val) => {
                      setPayments('transfer');
                    }}
                    checked={payments === 'transfer'}
                  />
                  <span />
                  <span>&nbsp; Chuyển khoản</span>
                </label>
              </div>
              <div className='mt-6'>
                <label className='radio radio-lg radio-single d-flex align-items-center'>
                  <input
                    className='w-15px h-15px'
                    name='payments'
                    type='radio'
                    onChange={(val) => {
                      setPayments('cod');
                    }}
                    checked={payments === 'cod'}
                  />
                  <span />
                  <span>&nbsp; COD</span>
                </label>
              </div>
            </div>
          </div>
          <div className='cart-order shadow'>
            <div className='h-100 d-flex align-items-center justify-content-between bg-white'>
              <button type='button' className='btn btn-white position-relative p-1 ms-3'>
                <i className='las text-primary la-cart-plus cursor-pointer cart-icon'></i>
                <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                  {cartSummary.totalProduct}
                </span>
              </button>
              <div className='d-flex h-100 d-flex align-items-center'>
                <div className='fw-bold text-danger fs-5 px-3'>
                  <FormattedNumber value={cartSummary.totalAmount} />đ
                </div>
                <div
                  className='h-100 w-100px bg-primary fw-bold fs-4 text-white d-flex align-items-center justify-content-center cursor-pointer'
                  // onClick={() => validateAndEnterCustomer()}
                  onClick={() => setShowModalConfirm(true)}
                >
                  <span>Đặt hàng</span>
                </div>
              </div>
            </div>
          </div>
          {userCustomer && (
            <ModalConfirmCustomerOrder
              userCustomer={userCustomer}
              className='mw-100 modal-dialog-centered'
              show={showModalConfirm}
              onHide={() => setShowModalConfirm(false)}
              onPayCart={validateAndEnterCustomer}
              setTextNote={setTextNote}
              textNote={textNote}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div className='px-4 pt-4 bg-white h-100 d-flex justify-content-center'>
        <div style={{maxWidth: '600px'}}>
          <div className='d-flex justify-content-between'>
            <div>
              {phoneCustomer && (
                <div
                  className='text-end cursor-pointer fw-bold text-decoration-underline'
                  onClick={() => setShowHistoryOrder(true)}
                >
                  Lịch sử đặt hàng
                </div>
              )}
            </div>
            <div>
              <i
                onClick={handleLogout}
                className='fs-1 fa-solid fa-right-from-bracket text-dark'
              ></i>
            </div>
          </div>
          <div>
            {
              orderLandingPageDetail?.settings?.banners?.desktop?.length > 0 && (
                <img
                  src={orderLandingPageDetail?.settings?.banners?.desktop[0]}
                  className={`w-100 d-lg-block d-xl-block d-xxl-block d-sm-none d-md-none `}
                  alt=''
                />
              )}
            {
              orderLandingPageDetail?.settings?.banners?.mobile?.length > 0 && (
                <img
                  src={orderLandingPageDetail?.settings?.banners?.mobile[0]}
                  className={`w-100  d-lg-none d-sm-block d-md-block`}
                  alt=''
                />
              )}
          </div>
          <div className=' fw-bold text pt-5 fs-2'>{orderBatchDetail.name}</div>
          <div
            className='fs-5 pt-2'
            style={{textAlign: 'unset'}}
            dangerouslySetInnerHTML={{__html: orderLandingPageDetail.description}}
          ></div>
          <Tab.Container defaultActiveKey={selectedTab}>
            <div className='d-flex align-items-center justify-content-between mb-5 border-bottom'>
              <Nav variant='underline' onSelect={setSelectedTab} as='ul' role='tablist'>
                {Object.entries(productByCategory).map(([key, categories]) => {
                  return (
                    <Nav.Item as='li' key={key}>
                      <Nav.Link
                        eventKey={key}
                        className={`cursor-pointer nav-link fw-bold fs-3 text-warning ${
                          selectedTab === key ? 'border-bottom border-primary border-2' : ''
                        }`}
                      >
                        <span className={clsx({'text-primary': selectedTab === key})}>
                          {categories[0]?.productCategory?.name || 'Khác'}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </div>
            {/*end::Header*/}

            {/*begin::Content*/}
            <div className=''>
              <div className='tab-content'>
                {Object.entries(productByCategory).map(([key, categories]) => {
                  return (
                    <div
                      key={key}
                      id='kt_quick_panel_products'
                      role='tabpanel'
                      className={`tab-pane fade pt-3 pe-5 me-n5 scroll ps ${
                        selectedTab === key ? 'active show' : ''
                      }`}
                    >
                      <div className='mb-15'>
                        <div className='px-2 mt-2 pb-20'>
                          {categories?.map((product) => {
                            const cartProduct = cartProductsById?.[product?._id];
                            let productPrice = productById?.[product?._id];

                            return (
                              <div
                                key={product._id}
                                className='row  border-light border-bottom rounded p-1 mb-3'
                              >
                                <div className='col-3 align-items-center '>
                                  <img
                                    src={
                                      product.image_url || toAbsoluteUrl('/media/products/23.png')
                                    }
                                    className={`w-100 rounded ${product.image_url ? '' : ''} `}
                                    alt=''
                                  />
                                </div>
                                <div className='col-9'>
                                  <div className='mb-1 fw-bold fs-4'>{product.name}</div>
                                  <div className='mb-1 d-flex justify-content-between'>
                                    <div>
                                      {productPrice?.priceDiscount > 0 && (
                                        <span className='text-decoration-line-through me-1'>
                                          <FormattedNumber value={productPrice?.productPrice} />
                                        </span>
                                      )}
                                      <span className='text-danger'>
                                        <FormattedNumber value={productPrice?.price} />đ
                                      </span>
                                      <span className='ms-2'>
                                        Còn: {product.availableQuantity} {product.unit_name || 'kg'}
                                      </span>
                                    </div>
                                    <div className='mb-1'>
                                      {product.max_order > 0 &&
                                        `Đặt dưới: ${product.max_order} ${
                                          product.unit_name || 'kg'
                                        } `}
                                    </div>
                                  </div>

                                  <div className='product-package w-100 mt-3'>
                                    {product?.packages?.map((packageItem) => {
                                      const cartPackage =
                                        cartProduct?.packageItems?.[packageItem?._id];
                                      return (
                                        <div
                                          className='d-flex w-100 justify-content-end align-items-center mt-auto mb-2'
                                          key={packageItem._id}
                                        >
                                          <span className='w-100px'>{packageItem.name}</span>
                                          <div className='d-flex'>
                                            {cartPackage?.packageQuantity ? (
                                              <div className='minus-sign'>
                                                <i
                                                  className={`las la-minus border border-dark text-dark rounded p-1 h-25px w-25px d-flex align-items-center justify-content-center ${clsx(
                                                    {'opacity-0': !cartPackage}
                                                  )}`}
                                                  onClick={() => {
                                                    if (cartPackage) {
                                                      handelDeleteToCart(packageItem, product);
                                                    }
                                                  }}
                                                ></i>
                                              </div>
                                            ) : null}
                                            <span
                                              className={`fw-bold  mx-2 h-25px w-25px d-flex align-items-center justify-content-center`}
                                            >
                                              {cartPackage?.packageQuantity || ''}
                                            </span>
                                            <i
                                              className='las la-plus bg-primary text-white rounded  p-1 h-25px w-25px d-flex align-items-center justify-content-center'
                                              onClick={() => handelAddToCart(packageItem, product)}
                                            ></i>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  {totalProductQuantity(product) > 0 && (
                                    <div className='me-2 badge bg-success d-none '>
                                      <span>
                                        Đã đặt: {totalProductQuantity(product)}{' '}
                                        {product.unit_name || 'kg'}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/*end::Content*/}
          </Tab.Container>
        </div>
        <div className='cart-order shadow'>
          <div className='h-100 d-flex align-items-center justify-content-between bg-white'>
            <button type='button' className='btn btn-white position-relative p-1 ms-3'>
              <i className='las text-primary la-cart-plus cursor-pointer cart-icon'></i>
              <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                {cartSummary.totalProduct}
              </span>
            </button>
            <div className='d-flex h-100 d-flex align-items-center'>
              <div className='fw-bold text-primary fs-5 px-3'>
                <FormattedNumber value={cartSummary.totalAmount} />đ
              </div>
              <div
                className='h-100 w-100px bg-primary fw-bold fs-4 text-white d-flex align-items-center cursor-pointer justify-content-center'
                onClick={() => {
                  cartSummary?.totalProduct > 0 && setShowCartOrder(true);
                }}
                disabled={cartSummary.totalProduct === 0}
              >
                <span>Đặt hàng</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCustomerOrder
        className='modal-dialog-centered'
        show={showCustomerOrder}
        onHide={() => setShowCustomerOrder(false)}
        onPayCart={() => setShowCustomerOrder(false)}
      />
    </>
  );
}
